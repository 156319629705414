<template>
  <div>
    <div class="container">
      <div class="card">
        <div class="header-section">
          <div>
            <h1>{{ props.measure.measure_number }} - {{ props.measure.measure_type }}</h1>
            <p class="meta">{{ $t('measure.fields.audit_number') }}: {{ props.measure.audit_number }}</p>
            <div class='meta-grid'>
              <p class="meta">{{ $t('measure.fields.company') }}: {{ props.company.name }}</p>
              <p class="meta">{{ $t('measure.fields.process_code') }}: {{ props.measure.process_code }}</p>
              <p class="meta">{{ $t('measure.fields.measure_follower_email') }}: {{ props.measure.measure_follower_email }}</p>
              <!-- wenn start und end datum gesetzt -->
              <div v-if="props.measure.start_date && props.measure.end_date" class="meta">
                {{ $t('measure.from_to') }}: {{ props.measure.start_date }} - {{ props.measure.end_date }}
              </div>
            </div>
            <div v-if="props.measure.audit_standard" class="audit-standard-badge">{{ props.measure.audit_standard }}</div>
          </div>
          <div :class="`status-badge status-badge-large status-${props.measure.status}`">{{ props.measure.status }}</div>
        </div>
      </div>
      <div class="card">
        <form :action="`/company/measures/${props.measure.id}`" method="post">
          <input type="hidden" name="_method" value="put" />
          <input
            name="authenticity_token"
            type="hidden"
            :value="props.form_authenticity_token"
          />

          <DxTabPanel>
            <DxItem :title="`${$t('measure.fields.deviation')}`" icon="comment">
              <DxHtmlEditor
                v-model:value="props.measure.deviation"
                height="400px"
                width="'100%'"
                name="deviation"
              >
                <DxMediaResizing :enabled="true" />
                <DxImageUpload :tabs="currentTabs" file-upload-mode="base64" />
                <DxToolbar :multiline="isMultiline">
                  <DxItem name="undo" />
                  <DxItem name="redo" />
                  <DxItem name="separator" />
                  <DxItem
                    name="size"
                    :accepted-values="sizeValues"
                    :options="fontSizeOptions"
                  />
                  <DxItem
                    name="font"
                    :accepted-values="fontValues"
                    :options="fontFamilyOptions"
                  />
                  <DxItem name="separator" />
                  <DxItem name="bold" />
                  <DxItem name="italic" />
                  <DxItem name="strike" />
                  <DxItem name="underline" />
                  <DxItem name="separator" />
                  <DxItem name="alignLeft" />
                  <DxItem name="alignCenter" />
                  <DxItem name="alignRight" />
                  <DxItem name="alignJustify" />
                  <DxItem name="separator" />
                  <DxItem name="orderedList" />
                  <DxItem name="bulletList" />
                  <DxItem name="separator" />
                  <DxItem
                    name="header"
                    :accepted-values="headerValues"
                    :options="headerOptions"
                  />
                  <DxItem name="separator" />
                  <DxItem name="color" />
                  <DxItem name="background" />
                  <DxItem name="separator" />
                  <DxItem name="link" />
                  <DxItem name="image" />
                  <DxItem name="separator" />
                  <DxItem name="clear" />
                  <DxItem name="codeBlock" />
                  <DxItem name="blockquote" />
                  <DxItem name="separator" />
                  <DxItem name="insertTable" />
                  <DxItem name="deleteTable" />
                  <DxItem name="insertRowAbove" />
                  <DxItem name="insertRowBelow" />
                  <DxItem name="deleteRow" />
                  <DxItem name="insertColumnLeft" />
                  <DxItem name="insertColumnRight" />
                  <DxItem name="deleteColumn" />
                </DxToolbar>
              </DxHtmlEditor>
            </DxItem>
            <DxItem :title="`${$t('measure.fields.cause')}`" icon="comment">
              <DxHtmlEditor
                v-model:value="props.measure.cause"
                height="400px"
                width="'100%'"
                name="cause"
              >
                <DxMediaResizing :enabled="true" />
                <DxImageUpload :tabs="currentTabs" file-upload-mode="base64" />
                <DxToolbar :multiline="isMultiline">
                  <DxItem name="undo" />
                  <DxItem name="redo" />
                  <DxItem name="separator" />
                  <DxItem
                    name="size"
                    :accepted-values="sizeValues"
                    :options="fontSizeOptions"
                  />
                  <DxItem
                    name="font"
                    :accepted-values="fontValues"
                    :options="fontFamilyOptions"
                  />
                  <DxItem name="separator" />
                  <DxItem name="bold" />
                  <DxItem name="italic" />
                  <DxItem name="strike" />
                  <DxItem name="underline" />
                  <DxItem name="separator" />
                  <DxItem name="alignLeft" />
                  <DxItem name="alignCenter" />
                  <DxItem name="alignRight" />
                  <DxItem name="alignJustify" />
                  <DxItem name="separator" />
                  <DxItem name="orderedList" />
                  <DxItem name="bulletList" />
                  <DxItem name="separator" />
                  <DxItem
                    name="header"
                    :accepted-values="headerValues"
                    :options="headerOptions"
                  />
                  <DxItem name="separator" />
                  <DxItem name="color" />
                  <DxItem name="background" />
                  <DxItem name="separator" />
                  <DxItem name="link" />
                  <DxItem name="image" />
                  <DxItem name="separator" />
                  <DxItem name="clear" />
                  <DxItem name="codeBlock" />
                  <DxItem name="blockquote" />
                  <DxItem name="separator" />
                  <DxItem name="insertTable" />
                  <DxItem name="deleteTable" />
                  <DxItem name="insertRowAbove" />
                  <DxItem name="insertRowBelow" />
                  <DxItem name="deleteRow" />
                  <DxItem name="insertColumnLeft" />
                  <DxItem name="insertColumnRight" />
                  <DxItem name="deleteColumn" />
                </DxToolbar>
              </DxHtmlEditor>
            </DxItem>
            <DxItem
              :title="`${$t('measure.fields.planned_measure')}`"
              icon="comment"
            >
              <DxHtmlEditor
                v-model:value="props.measure.planned_measure"
                height="400px"
                width="'100%'"
                name="planned_measure"
              >
                <DxMediaResizing :enabled="true" />
                <DxImageUpload :tabs="currentTabs" file-upload-mode="base64" />
                <DxToolbar :multiline="isMultiline">
                  <DxItem name="undo" />
                  <DxItem name="redo" />
                  <DxItem name="separator" />
                  <DxItem
                    name="size"
                    :accepted-values="sizeValues"
                    :options="fontSizeOptions"
                  />
                  <DxItem
                    name="font"
                    :accepted-values="fontValues"
                    :options="fontFamilyOptions"
                  />
                  <DxItem name="separator" />
                  <DxItem name="bold" />
                  <DxItem name="italic" />
                  <DxItem name="strike" />
                  <DxItem name="underline" />
                  <DxItem name="separator" />
                  <DxItem name="alignLeft" />
                  <DxItem name="alignCenter" />
                  <DxItem name="alignRight" />
                  <DxItem name="alignJustify" />
                  <DxItem name="separator" />
                  <DxItem name="orderedList" />
                  <DxItem name="bulletList" />
                  <DxItem name="separator" />
                  <DxItem
                    name="header"
                    :accepted-values="headerValues"
                    :options="headerOptions"
                  />
                  <DxItem name="separator" />
                  <DxItem name="color" />
                  <DxItem name="background" />
                  <DxItem name="separator" />
                  <DxItem name="link" />
                  <DxItem name="image" />
                  <DxItem name="separator" />
                  <DxItem name="clear" />
                  <DxItem name="codeBlock" />
                  <DxItem name="blockquote" />
                  <DxItem name="separator" />
                  <DxItem name="insertTable" />
                  <DxItem name="deleteTable" />
                  <DxItem name="insertRowAbove" />
                  <DxItem name="insertRowBelow" />
                  <DxItem name="deleteRow" />
                  <DxItem name="insertColumnLeft" />
                  <DxItem name="insertColumnRight" />
                  <DxItem name="deleteColumn" />
                </DxToolbar>
              </DxHtmlEditor>
            </DxItem>
            <DxItem :title="`${$t('measure.fields.measure')}`" icon="comment">
              <DxHtmlEditor
                v-model:value="props.measure.measure"
                height="400px"
                width="'100%'"
                name="measure"
              >
                <DxMediaResizing :enabled="true" />
                <DxImageUpload :tabs="currentTabs" file-upload-mode="base64" />
                <DxToolbar :multiline="isMultiline">
                  <DxItem name="undo" />
                  <DxItem name="redo" />
                  <DxItem name="separator" />
                  <DxItem
                    name="size"
                    :accepted-values="sizeValues"
                    :options="fontSizeOptions"
                  />
                  <DxItem
                    name="font"
                    :accepted-values="fontValues"
                    :options="fontFamilyOptions"
                  />
                  <DxItem name="separator" />
                  <DxItem name="bold" />
                  <DxItem name="italic" />
                  <DxItem name="strike" />
                  <DxItem name="underline" />
                  <DxItem name="separator" />
                  <DxItem name="alignLeft" />
                  <DxItem name="alignCenter" />
                  <DxItem name="alignRight" />
                  <DxItem name="alignJustify" />
                  <DxItem name="separator" />
                  <DxItem name="orderedList" />
                  <DxItem name="bulletList" />
                  <DxItem name="separator" />
                  <DxItem
                    name="header"
                    :accepted-values="headerValues"
                    :options="headerOptions"
                  />
                  <DxItem name="separator" />
                  <DxItem name="color" />
                  <DxItem name="background" />
                  <DxItem name="separator" />
                  <DxItem name="link" />
                  <DxItem name="image" />
                  <DxItem name="separator" />
                  <DxItem name="clear" />
                  <DxItem name="codeBlock" />
                  <DxItem name="blockquote" />
                  <DxItem name="separator" />
                  <DxItem name="insertTable" />
                  <DxItem name="deleteTable" />
                  <DxItem name="insertRowAbove" />
                  <DxItem name="insertRowBelow" />
                  <DxItem name="deleteRow" />
                  <DxItem name="insertColumnLeft" />
                  <DxItem name="insertColumnRight" />
                  <DxItem name="deleteColumn" />
                </DxToolbar>
              </DxHtmlEditor>
            </DxItem>
            <DxItem :title="`${$t('measure.fields.risks')}`" icon="comment">
              <DxHtmlEditor
                v-model:value="props.measure.risks"
                height="400px"
                width="'100%'"
                name="risks"
              >
                <DxMediaResizing :enabled="true" />
                <DxImageUpload :tabs="currentTabs" file-upload-mode="base64" />
                <DxToolbar :multiline="isMultiline">
                  <DxItem name="undo" />
                  <DxItem name="redo" />
                  <DxItem name="separator" />
                  <DxItem
                    name="size"
                    :accepted-values="sizeValues"
                    :options="fontSizeOptions"
                  />
                  <DxItem
                    name="font"
                    :accepted-values="fontValues"
                    :options="fontFamilyOptions"
                  />
                  <DxItem name="separator" />
                  <DxItem name="bold" />
                  <DxItem name="italic" />
                  <DxItem name="strike" />
                  <DxItem name="underline" />
                  <DxItem name="separator" />
                  <DxItem name="alignLeft" />
                  <DxItem name="alignCenter" />
                  <DxItem name="alignRight" />
                  <DxItem name="alignJustify" />
                  <DxItem name="separator" />
                  <DxItem name="orderedList" />
                  <DxItem name="bulletList" />
                  <DxItem name="separator" />
                  <DxItem
                    name="header"
                    :accepted-values="headerValues"
                    :options="headerOptions"
                  />
                  <DxItem name="separator" />
                  <DxItem name="color" />
                  <DxItem name="background" />
                  <DxItem name="separator" />
                  <DxItem name="link" />
                  <DxItem name="image" />
                  <DxItem name="separator" />
                  <DxItem name="clear" />
                  <DxItem name="codeBlock" />
                  <DxItem name="blockquote" />
                  <DxItem name="separator" />
                  <DxItem name="insertTable" />
                  <DxItem name="deleteTable" />
                  <DxItem name="insertRowAbove" />
                  <DxItem name="insertRowBelow" />
                  <DxItem name="deleteRow" />
                  <DxItem name="insertColumnLeft" />
                  <DxItem name="insertColumnRight" />
                  <DxItem name="deleteColumn" />
                </DxToolbar>
              </DxHtmlEditor>
            </DxItem>
            <DxItem
              :title="`${$t('measure.fields.opportunities')}`"
              icon="comment"
            >
              <DxHtmlEditor
                v-model:value="props.measure.opportunities"
                height="400px"
                width="'100%'"
                name="opportunities"
              >
                <DxMediaResizing :enabled="true" />
                <DxImageUpload :tabs="currentTabs" file-upload-mode="base64" />
                <DxToolbar :multiline="isMultiline">
                  <DxItem name="undo" />
                  <DxItem name="redo" />
                  <DxItem name="separator" />
                  <DxItem
                    name="size"
                    :accepted-values="sizeValues"
                    :options="fontSizeOptions"
                  />
                  <DxItem
                    name="font"
                    :accepted-values="fontValues"
                    :options="fontFamilyOptions"
                  />
                  <DxItem name="separator" />
                  <DxItem name="bold" />
                  <DxItem name="italic" />
                  <DxItem name="strike" />
                  <DxItem name="underline" />
                  <DxItem name="separator" />
                  <DxItem name="alignLeft" />
                  <DxItem name="alignCenter" />
                  <DxItem name="alignRight" />
                  <DxItem name="alignJustify" />
                  <DxItem name="separator" />
                  <DxItem name="orderedList" />
                  <DxItem name="bulletList" />
                  <DxItem name="separator" />
                  <DxItem
                    name="header"
                    :accepted-values="headerValues"
                    :options="headerOptions"
                  />
                  <DxItem name="separator" />
                  <DxItem name="color" />
                  <DxItem name="background" />
                  <DxItem name="separator" />
                  <DxItem name="link" />
                  <DxItem name="image" />
                  <DxItem name="separator" />
                  <DxItem name="clear" />
                  <DxItem name="codeBlock" />
                  <DxItem name="blockquote" />
                  <DxItem name="separator" />
                  <DxItem name="insertTable" />
                  <DxItem name="deleteTable" />
                  <DxItem name="insertRowAbove" />
                  <DxItem name="insertRowBelow" />
                  <DxItem name="deleteRow" />
                  <DxItem name="insertColumnLeft" />
                  <DxItem name="insertColumnRight" />
                  <DxItem name="deleteColumn" />
                </DxToolbar>
              </DxHtmlEditor>
            </DxItem>
          </DxTabPanel>

          <hr />

          <div class="actions">
            <a :href="props.back_path">
              <DxButton
                icon="back"
                type="primary"
                :text="`${$t('actions.back')}`"
                styling-mode="outlined"
              />
            </a>
            <DxButton
              :text="`${$t('measure.update_measure')}`"
              type="success"
              :use-submit-behavior="true"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import DxButton from "devextreme-vue/button";
import DxForm from "devextreme-vue/form";
import { DxTabPanel } from "devextreme-vue/tab-panel";
import {
  DxHtmlEditor,
  DxToolbar,
  DxMediaResizing,
  DxImageUpload,
  DxItem,
} from "devextreme-vue/html-editor";

const tabs = [
  { name: "From This Device", value: ["file"] },
  { name: "From the Web", value: ["url"] },
  { name: "Both", value: ["file", "url"] },
];

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
const headerValues = [false, 1, 2, 3, 4, 5];
const currentTabs = ref(tabs[2].value);
const isMultiline = ref(true);
const fontSizeOptions = { inputAttr: { "aria-label": "Font size" } };
const fontFamilyOptions = { inputAttr: { "aria-label": "Font family" } };
const headerOptions = { inputAttr: { "aria-label": "Font family" } };

const props = defineProps({
  measure: {
    type: Object,
    required: true,
  },
  company: {
    type: Object,
    required: true,
  },
  form_authenticity_token: {
    type: String,
    required: true,
  },
  form_url: {
    type: String,
    required: true,
  },
  form_method: {
    type: String,
    required: true,
  },
  back_path: {
    type: String,
    required: true,
  },
});
</script>
<style scoped>

#form {
  margin-top: 25px;
}
.label {
  font-weight: bold;
}

.header-section{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  h1 {
    font-size: 34px;
    font-weight: 300;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 20px;
    color: #777;
    letter-spacing: 1px;
  }
  & > div {
    width: 100%
  }

  .measure-dates{
    font-size: 14px;
    color: #999;
    margin-top: 8px;
    margin-bottom: 20px;
    display: inline;
  }
  .audit-standard-badge{
    background-color: #f0f0f0;
    color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    display: inline-block;
  }

  .meta-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 3px 30px;
  }

  .status-badge{
    /* top right absolute */
    position: absolute;
    width: auto;
    top: 0;
    right: 0;
  }
}
</style>
