<template>
  <div class="page-card">
    <!-- Begrüßungstext -->
    <div class="welcome-section">
      <h1>{{ $t('welcome.title', { name: props.user_name || props.user.email }) }}</h1>
      <p>{{ $t('welcome.description', { company: props.company.name }) }}</p>
    </div>

    <!-- Maßnahmenübersicht -->
    <h2>{{ $t('measure.title') }}</h2>
    <p>{{ $t('measure.description') }}</p>

    <DxDataGrid
      :show-borders="true"
      :data-source="props.measures"
      :column-hiding-enabled="true"
      :focused-row-enabled="true"
      :focused-row-key="focusedRowKey"
      key-expr="id"
      @row-click="rowClick"
      :column-auto-width="true"
    >
      <!-- Paging Settings -->
      <DxPaging :enabled="true" :pageSize="5" />

      <!-- Pager Settings -->
      <DxPager
        :visible="true"
        :showPageSizeSelector="true"
        :allowedPageSizes="[5, 10, 20]"
        :showInfo="true"
        :showNavigationButtons="true"
      />
      <DxColumn
        cell-template="actionTemplate"
        :caption="`${$t('measure.fields.actions')}`"
        :visible-index="10"
        :hiding-priority="9"
      />
      <DxColumn
        dataField="measure_number"
        :caption="`${$t('measure.fields.measure_number')}`"
        :visible-index="2"
        :hiding-priority="10"
      />
      <DxColumn
        dataField="audit_number"
        :caption="`${$t('measure.fields.audit_number')}`"
        :visible-index="3"
        :hiding-priority="1"
      />
      <DxColumn
        dataField="start_date"
        :caption="`${$t('measure.fields.start_date')}`"
        :visible-index="5"
        :hiding-priority="2"
      />
      <DxColumn
        dataField="end_date"
        :caption="`${$t('measure.fields.end_date')}`"
        :visible-index="5"
        :hiding-priority="3"
      />
      <DxColumn
        dataField="measure_type"
        :caption="`${$t('measure.fields.measure_type')}`"
        :visible-index="4"
        :hiding-priority="4"
      />
      <DxColumn
        dataField="status"
        cell-template="statusTemplate"
        :caption="`${$t('measure.fields.status')}`"
        :visible-index="6"
        :hiding-priority="5"

      />
      <DxGridToolbar>
        <DxGridToolbarItem location="before">
          <div class="grid-header">
            {{ $t("user.one") }} <b>{{ props.user_name }}</b> /
            {{ $t("measure.index") }}
          </div>
        </DxGridToolbarItem>
      </DxGridToolbar>

      <template #statusTemplate="{ data }">
        <div :class="`status-badge status-${data.data.status}`">
          {{ data.data.status }}
        </div>
      </template>

      <template #actionTemplate="{ data }">
        <div class="actions">
          <a 
            :href="`/company/measures/${data.data.id}/edit`"
            v-if="data.data.status != 'completed'"
            >
            <DxButton
              :text="`${$t('actions.edit')}`"
              icon="datausage"
              type="primary"
              stylingMode="contained"
            />
          </a>
          <a 
            :href="`/company/measures/${data.data.id}`"
            v-if="data.data.status == 'completed'"
            >
            <DxButton
              :text="`${$t('actions.show')}`"
              icon="datausage"
              type="primary"
              stylingMode="contained"
            />
          </a>
          <a
            :href="`/company/measures/${data.data.id}?status=completed`"
            data-method="PUT"
            :data-confirm="`${$t('actions.confirm_completion')}`"
            v-if="data.data.status == 'draft'"
          >
            <DxButton
              :text="`${$t('actions.complete')}`"
              icon="edit"
              type="success"
              stylingMode="contained"
            />
          </a>
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import DxDataGrid, {
  DxToolbar as DxGridToolbar,
  DxItem as DxGridToolbarItem,
  DxColumn,
  DxPaging,
  DxPager,
} from "devextreme-vue/data-grid";
import { DxButton } from "devextreme-vue/button";
import { DxProgressBar } from "devextreme-vue/progress-bar";

const focusedRowKey = ref(null);

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  company: {
    type: Object,
    required: true,
  },
  user_name: {
    type: String,
  },
  measures: {
    type: Array,
    required: true,
  },
});

const rowClick = (event) => {
  focusedRowKey.value = event.data.id;
};

const handleAssignMeasure = () => {
  window.location.href = `/admin/companies/${props.company.id}/users/${props.user.id}/measures/new`;
};
</script>

<style lang="scss">
.welcome-section {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;

  h1 {
    font-size: 38px !important;
    margin-bottom: 10px;
  }

  p {
    font-weight: 300;
    font-size: 18px;
    color: #555;
  }
}

h2 {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #333 !important;
    margin-bottom: 10px;
  }

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
</style>
