<template>
  <div></div>
</template>

<script setup>
import { onMounted } from "vue";
import notify from "devextreme/ui/notify";

const props = defineProps({
  flash_messages: {
    type: Array,
  },
  errors: {
    type: Array,
  },
});

const renderFlashMessages = () => {
  props.flash_messages.forEach((message) => {
    showNotify(message[1], message[0]);
  });
};

const renderErrors = () => {
  props.errors.forEach((error) => {
    showNotify(error, "error");
  });
};

const showNotify = (message, type) => {
  notify(
    {
      message: message,
      height: 45,
      width: 350,
      minWidth: 150,
      type: mapTypes(type),
      displayTime: 3500,
      animation: {
        show: {
          type: "fade",
          duration: 400,
          from: 0,
          to: 1,
        },
        hide: { type: "fade", duration: 40, to: 0 },
      },
    },
    { position: "top center", direction: "up-stack" }
  );
};

const mapTypes = (type) => {
  return {
    alert: "error",
    notice: "success",
    error: "error",
  }[type];
};

onMounted(() => {
  renderFlashMessages();
  renderErrors();
});
</script>

<style></style>
