<template>
  <div class="page-card">
    <DxDataGrid
      :show-borders="true"
      :data-source="props.users"
      :focused-row-enabled="true"
      :focused-row-key="focusedRowKey"
      key-expr="id"
      @row-click="rowClick"
      :column-auto-width="true"
    >
      <!-- Paging Settings -->
      <DxPaging :enabled="true" :pageSize="5" />

      <!-- Pager Settings -->
      <DxPager
        :visible="true"
        :showPageSizeSelector="true"
        :allowedPageSizes="[5, 10, 20]"
        :showInfo="true"
        :showNavigationButtons="true"
      />

      <DxColumn
        dataField="id"
        :width="50"
        :caption="`${$t('user.fields.id')}`"
      />
      <DxColumn
        dataField="company_id_ims"
        :caption="`${$t('user.fields.company_id_ims')}`"
      />
      <DxColumn
        dataField="user_id_ims"
        :caption="`${$t('user.fields.user_id_ims')}`"
      />
      <DxColumn dataField="email" :caption="`${$t('user.fields.email')}`" />
      <DxColumn
        dataField="first_name"
        :caption="`${$t('user.fields.first_name')}`"
      />
      <DxColumn
        dataField="last_name"
        :caption="`${$t('user.fields.last_name')}`"
      />

      <DxColumn
        cell-template="actionTemplate"
        :caption="`${$t('user.fields.actions')}`"
      />

      <DxGridToolbar>
        <DxGridToolbarItem location="before">
          <a :href="props.back_path">
            <DxButton icon="back" type="primary" styling-mode="outlined" />
          </a>
        </DxGridToolbarItem>
        <DxGridToolbarItem location="before">
          <div class="grid-header">
            {{ $t("company.one") }} <b>{{ props.company.name }}</b> /
            {{ $t("company.users") }}
          </div>
        </DxGridToolbarItem>

        <DxGridToolbarItem location="after" locate-in-menu="auto">
          <div>
            <DxButton
              :text="`${$t('user.invite_user')}`"
              icon="plus"
              type="primary"
              styling-mode="outlined"
              @click="handleInviteUser"
            />
          </div>
        </DxGridToolbarItem>
      </DxGridToolbar>

      <template #actionTemplate="{ data }">
        <div class="actions">
          <a
            :href="`/admin/companies/${props.company.id}/users/${data.data.id}/measures`"
          >
            <DxButton
              :text="`${$t('user.measures')}`"
              icon="datausage"
              type="success"
              stylingMode="contained"
            />
          </a>
          <a
            :href="`/admin/companies/${props.company.id}/users/${data.data.id}/measures/new`"
          >
            <DxButton
              :text="`${$t('user.assign_measure')}`"
              icon="datausage"
              type="primary"
              stylingMode="contained"
            />
          </a>

          <a
            :href="`/admin/companies/${props.company.id}/users/${data.data.id}/reset_password`"
            data-method="PUT"
            :data-confirm="`${$t('actions.confirm')}`"
          >
            <DxButton
              :text="`${$t('actions.reset_password')}`"
              icon="email"
              type="normal"
              stylingMode="contained"
            />
          </a>
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import DxDataGrid, {
  DxToolbar as DxGridToolbar,
  DxItem as DxGridToolbarItem,
  DxColumn,
  DxPaging,
  DxPager,
} from "devextreme-vue/data-grid";
import { DxButton } from "devextreme-vue/button";

const focusedRowKey = ref(null);

const props = defineProps({
  company: {
    type: Object,
    required: true,
  },
  users: {
    type: Array,
    required: true,
  },
  new_admin_company_user_path: {
    type: String,
    required: true,
  },
  back_path: {
    type: String,
    required: true,
  },
});

const rowClick = (event) => {
  focusedRowKey.value = event.data.id;
};

const handleInviteUser = () => {
  window.location.href = props.new_admin_company_user_path;
};
</script>

<style lang="scss">
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
</style>
