<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <!-- Logo Platzierung -->
      <dx-item location="before">
        <a :href="props.root_url">
          <img :src="props.logo_url" alt="Logo" class="header-logo" />
        </a>
      </dx-item>

      <dx-item location="after">
        <a :href="`/locales/en?root_url=${props.root_url}`">
          <dx-button>
            <b v-if="props.locale == 'en'">EN</b>
            <div v-else>EN</div>
          </dx-button>
        </a>
      </dx-item>
      <dx-item location="after">
        <a :href="`/locales/de?root_url=${props.root_url}`">
          <dx-button>
            <b v-if="props.locale == 'de'">DE</b>
            <div v-else>DE</div>
          </dx-button>
        </a>
      </dx-item>
      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
        <template #default>
          <div>
            <dx-button
              class="user-button authorization"
              :width="210"
              height="100%"
              styling-mode="text"
            >
              <user-panel
                :name="props.user_name"
                :menu-items="userMenuItems"
                menu-mode="context"
              />
            </dx-button>
          </div>
        </template>
      </dx-item>
    </dx-toolbar>
  </header>
</template>

<script setup>
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";

import UserPanel from "./user-panel.vue";

const props = defineProps({
  user_name: {
    type: String,
    required: true,
  },
  sign_out_url: {
    type: String,
    required: true,
  },
  locale: {
    type: String,
    required: true,
  },
  root_url: {
    type: String,
    required: true,
  },
  logout: {
    type: String,
    required: true,
  },
  logo_url: {
    type: String,
    required: true,
  }
});

const userMenuItems = [
  {
    text: props.logout,
    icon: "runner",
    onClick: onLogoutClick,
  },
];

function onLogoutClick() {
  fetch(props.sign_out_url, {
    method: "DELETE",
    headers: {
      "X-CSRF-Token": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.ok) {
        window.location.href = props.sign_out_url.includes("admin")
          ? "/admin"
          : "/";
      } else {
        console.error("Failed to sign out");
      }
    })
    .catch((error) => console.error("Error:", error));
}
</script>

<style lang="scss">
.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: #ff5722;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: 60px;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}

/* Logo Styling */
.header-logo {
  max-height: 36px;
  margin-left: 10px;
  width: auto;
  display: inline-block;
  vertical-align: middle;
}
</style>
