<template>
  <div class="page-card">
    <div class="widget-container">
      <form :action="`/admin/companies/${props.company.id}`" method="post">
        <input type="hidden" name="_method" value="put" />
        <input
          name="authenticity_token"
          type="hidden"
          :value="props.form_authenticity_token"
        />
        <DxForm
          :form-data="props.company"
          :label-mode="'floating'"
          :show-colon-after-label="true"
          :label-location="'left'"
          :min-col-width="'400'"
          :col-count="2"
        >
          <DxItem
            data-field="name"
            :label="{ text: $t('company.fields.name') }"
          ></DxItem>
          <DxItem
            data-field="company_id_ims"
            :label="{ text: $t('company.fields.company_id_ims') }"
            :disabled="true"
          ></DxItem>
        </DxForm>
        <div class="actions">
          <a :href="props.admin_companies_url">
            <DxButton
              icon="back"
              type="primary"
              :text="`${$t('actions.back')}`"
              styling-mode="outlined"
            />
          </a>
          <DxButton
            :text="`${$t('actions.update')}`"
            type="success"
            :use-submit-behavior="true"
          />
          <a
            :href="props.form_url"
            data-method="DELETE"
            :data-confirm="`${$t('actions.delete_confirm')}`"
          >
            <DxButton
              :text="`${$t('actions.delete')}`"
              icon="edit"
              type="danger"
              stylingMode="contained"
            />
          </a>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import DxButton from "devextreme-vue/button";
import DxForm, { DxItem } from "devextreme-vue/form";
const props = defineProps({
  company: {
    type: Object,
    required: true,
  },
  form_authenticity_token: {
    type: String,
    required: true,
  },
  form_url: {
    type: String,
    required: true,
  },
  form_method: {
    type: String,
    required: true,
  },
  admin_companies_url: {
    type: String,
    required: true,
  },
});

const handleDeleteCompany = () => {
  fetch(`/admin/companies/${props.company.id}`, {
    method: "DELETE",
    headers: {
      "X-CSRF-Token": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.ok) {
        // Redirect or refresh the page after sign out
        window.location.href = "/admin";
      }
    })
    .catch((error) => console.error("Error:", error));
};
</script>
<style scoped>
.widget-container {
  margin-right: 320px;
  padding: 20px;
  max-width: 550px;
  min-width: 300px;
}

#form {
  margin-top: 25px;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
</style>
