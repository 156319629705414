// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log("Vite ⚡️ Rails");

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log(
//   "Visit the guide for more information: ",
//   "https://vite-ruby.netlify.app/guide/rails"
// );

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import Rails from "@rails/ujs";
Rails.start();

import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import axios from "axios";

async function loadLocaleMessages(locale) {
  const response = await axios.get(`/locales`);
  return response.data;
}

async function setupI18n(locale = "en") {
  const messages = await loadLocaleMessages(locale);

  const i18n = createI18n({
    locale: locale,
    fallbackLocale: "en",
    messages: {
      [locale]: messages,
    },
  });

  return i18n;
}

// components
import Toast from "@/components/toast.vue";
import Navbar from "@/components/navbar.vue";
import CreateCompany from "@/components/create-company.vue";
import EditCompany from "@/components/edit-company.vue";
import CreateUser from "@/components/create-user.vue";

// pages
// guest
import Login from "@/pages/guests/login.vue";
import ForgotPassword from "@/pages/guests/forgot-password.vue";
import ChangePassword from "@/pages/guests/change-password.vue";

// admin
import AdminCompanies from "@/pages/admin/admin-companies.vue";
import CompanyUsers from "@/pages/admin/company-users.vue";
import CreateMeasure from "@/pages/admin/create-measure.vue";
import UserMeasures from "@/pages/admin/user-measures.vue";
import ViewMeasure from "@/pages/admin/view-measure.vue";

// company
import CompanyUserMeasures from "@/pages/company/company-user-measures.vue";
import UpdateMeasure from "@/pages/company/update-measure.vue";

import "devextreme/dist/css/dx.material.lime.light.css";
import "../styles.scss";

const components = {
  Login,
  ForgotPassword,
  ChangePassword,
  AdminCompanies,
  CompanyUsers,
  CreateMeasure,
  UserMeasures,
  ViewMeasure,
  Toast,
  Navbar,
  CreateCompany,
  EditCompany,
  CreateUser,
  CompanyUserMeasures,
  UpdateMeasure,
};

document.addEventListener("DOMContentLoaded", async () => {
  const locale = "en"; // TODO: or dynamically set based on user preference
  const i18n = await setupI18n(locale);

  Object.entries(components).forEach(([key, _component]) => {
    const DOM_element = document.getElementById(key);

    if (DOM_element) {
      const props = JSON.parse(DOM_element.getAttribute("data-props"));
      const component = createApp(_component, { ...props });
      component.use(i18n);
      component.mount(`#${key}`);
    }
  });
});
