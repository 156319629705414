<template>
  <div class="page-card">
    <DxDataGrid
      :show-borders="true"
      :data-source="props.companies"
      :focused-row-enabled="true"
      :focused-row-key="focusedRowKey"
      key-expr="id"
      @row-click="rowClick"
      :column-auto-width="true"
    >
      <!-- Paging Settings -->
      <DxPaging :enabled="true" :pageSize="5" />

      <!-- Pager Settings -->
      <DxPager
        :visible="true"
        :showPageSizeSelector="true"
        :allowedPageSizes="[5, 10, 20]"
        :showInfo="true"
        :showNavigationButtons="true"
      />

      <DxColumn dataField="name" :caption="`${$t('company.fields.name')}`" />
      <DxColumn
        dataField="company_id_ims"
        :caption="`${$t('company.fields.company_id_ims')}`"
      />

      <DxColumn
        caption="Actions"
        cell-template="actionTemplate"
        :caption="`${$t('company.fields.actions')}`"
      />

      <DxGridToolbar>
        <DxGridToolbarItem location="before">
          <div class="grid-header">{{ $t("company.index") }}</div>
        </DxGridToolbarItem>

        <DxGridToolbarItem location="after" locate-in-menu="auto">
          <div>
            <DxButton
              :text="`${$t('company.add_company')}`"
              icon="plus"
              type="primary"
              styling-mode="outlined"
              @click="handleAddCompany"
            />
          </div>
        </DxGridToolbarItem>
      </DxGridToolbar>

      <template #actionTemplate="{ data }">
        <div class="actions">
          <a :href="`/admin/companies/${data.data.id}/edit`">
            <DxButton
              :text="`${$t('actions.edit')}`"
              icon="edit"
              type="normal"
              stylingMode="contained"
            />
          </a>

          <a :href="`/admin/companies/${data.data.id}/users`">
            <DxButton
              :text="`${$t('company.users')}`"
              icon="group"
              type="success"
              stylingMode="contained"
            />
          </a>
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import DxDataGrid, {
  DxToolbar as DxGridToolbar,
  DxItem as DxGridToolbarItem,
  DxColumn,
  DxPaging,
  DxPager,
} from "devextreme-vue/data-grid";
import { DxButton } from "devextreme-vue/button";

const columns = [
  "name",
  "company_id_ims",
  "adapter",
  "host",
  "port",
  "database",
  "created_at",
];

const focusedRowKey = ref(null);

const props = defineProps({
  companies: {
    type: Array,
    required: true,
  },
  new_admin_company_path: {
    type: String,
    required: true,
  },
});

const rowClick = (event) => {
  focusedRowKey.value = event.data.id;
};

const handleAddCompany = () => {
  window.location.href = props.new_admin_company_path;
};

const handleDeleteCompany = (company_id) => {
  // console.log("DELETE COMPANY: ", company_id);
};

</script>

<style lang="scss">
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
</style>
