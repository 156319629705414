<template>
  <div class="page-card">
    <div class="widget-container">
      <form :action="props.form_url" :method="props.form_method">
        <input type="hidden" name="_method" :value="props.form_method" />
        <input
          name="authenticity_token"
          type="hidden"
          :value="props.form_authenticity_token"
        />
        <DxForm
          :form-data="props.company"
          :label-mode="'floating'"
          :show-colon-after-label="true"
          :label-location="'left'"
          :min-col-width="'400'"
          :col-count="2"
        >
          <DxItem
            data-field="name"
            :label="{ text: $t('company.fields.name') }"
          ></DxItem>
          <DxItem
            data-field="company_id_ims"
            :label="{ text: $t('company.fields.company_id_ims') }"
          ></DxItem>
        </DxForm>
        <div class="actions">
          <a :href="props.admin_companies_url">
            <DxButton
              icon="back"
              type="primary"
              :text="`${$t('actions.back')}`"
              styling-mode="outlined"
            />
          </a>
          <DxButton
            :text="`${$t('actions.create')}`"
            type="success"
            :use-submit-behavior="true"
          />
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import DxButton from "devextreme-vue/button";
import DxForm, { DxItem } from "devextreme-vue/form";

const props = defineProps({
  company: {
    type: Object,
    required: true,
  },
  form_authenticity_token: {
    type: String,
    required: true,
  },
  form_url: {
    type: String,
    required: true,
  },
  form_method: {
    type: String,
    required: true,
  },
  admin_companies_url: {
    type: String,
    required: true,
  },
});

const labelModes = ["outside", "static", "floating", "hidden"];
const labelLocations = ["left", "top"];
const columnsCounts = ["auto", 1, 2, 3];
const minColumnWidths = [150, 200, 300];

const labelMode = ref("floating");
const labelLocation = ref("left");
const readOnly = ref(false);
const showColon = ref(true);
const minColWidth = ref(300);
const colCount = ref(2);
const width = ref(null);
</script>
<style scoped>
.widget-container {
  margin-right: 320px;
  padding: 20px;
  max-width: 550px;
  min-width: 300px;
}

#form {
  margin-top: 25px;
}

.options {
  padding: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 260px;
  top: 0;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
</style>
