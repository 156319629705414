<template>
  <div class="page-card">
    <div class="widget-container">
      <form :action="props.form_url" :method="props.form_method">
        <input type="hidden" name="_method" :value="props.form_method" />
        <input
          name="authenticity_token"
          type="hidden"
          :value="props.form_authenticity_token"
        />
        <DxForm
          :form-data="props.measure"
          :label-mode="'floating'"
          :show-colon-after-label="true"
          :label-location="'left'"
          :min-col-width="'400'"
          :col-count="2"
        >
          <DxItem
            data-field="measure_number"
            :label="{ text: $t('measure.fields.measure_number') }"
          ></DxItem>
          <DxItem
            data-field="audit_number"
            :label="{ text: $t('measure.fields.audit_number') }"
          ></DxItem>
          <DxItem
            data-field="measure_id"
            :label="{ text: $t('measure.fields.measure_id') }"
          ></DxItem>
          <DxItem
            data-field="audit_id"
            :label="{ text: $t('measure.fields.audit_id') }"
          ></DxItem>
          <DxItem
            data-field="process_code"
            :label="{ text: $t('measure.fields.process_code') }"
          ></DxItem>
          <DxItem
            data-field="audit_standard"
            :label="{ text: $t('measure.fields.audit_standard') }"
          ></DxItem>
          <DxItem
            data-field="revision_status"
            :label="{ text: $t('measure.fields.revision_status') }"
          ></DxItem>
          <DxItem
            data-field="start_date"
            :label="{ text: $t('measure.fields.start_date') }"
          ></DxItem>
          <DxItem
            data-field="end_date"
            :label="{ text: $t('measure.fields.end_date') }"
          ></DxItem>
          <DxItem
            data-field="measure_type"
            :label="{ text: $t('measure.fields.measure_type') }"
          ></DxItem>

          <DxItem
            data-field="measure_follower_email"
            :label="{ text: $t('measure.fields.measure_follower_email') }"
          ></DxItem>
        </DxForm>
        <div class="actions">
          <a :href="props.back_path">
            <DxButton
              icon="back"
              type="primary"
              :text="`${$t('actions.back')}`"
              styling-mode="outlined"
            />
          </a>
          <DxButton
            :text="`${$t('user.assign_measure')}`"
            type="success"
            :use-submit-behavior="true"
          />
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import DxButton from "devextreme-vue/button";
import DxForm, { DxItem } from "devextreme-vue/form";

const props = defineProps({
  company: {
    type: Object,
    required: true,
  },
  user: {
    type: Object,
    required: true,
  },
  measure: {
    type: Object,
    required: true,
  },
  form_authenticity_token: {
    type: String,
    required: true,
  },
  form_url: {
    type: String,
    required: true,
  },
  form_method: {
    type: String,
    required: true,
  },
  back_path: {
    type: String,
    required: true,
  },
});
</script>
<style scoped>
.widget-container {
  margin-right: 320px;
  padding: 20px;
  max-width: 550px;
  min-width: 300px;
}

#form {
  margin-top: 25px;
}

.options {
  padding: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 260px;
  top: 0;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}
</style>
