<template>
  <div class="container">
    <DxToolbar>
      <DxToolbarItem location="before">
        <a :href="props.back_path">
          <DxButton icon="back" type="primary" styling-mode="outlined" />
        </a>
      </DxToolbarItem>
      <DxToolbarItem location="before">
        <div class="grid-header">
          Maßnahmennummer oben
          {{ $t("measure.one") }} <b>{{ `${props.measure.measure_number}` }}</b> /
          {{ $t("measure.details") }}
          <div :class="`status-badge status-${props.measure.status}`">
            {{ props.measure.status }}
          </div>
        </div>
      </DxToolbarItem>
    </DxToolbar>
    <div class="card">
      <div class="header-section">
        <div>
          <h1>{{ props.measure.measure_number }} - {{ props.measure.measure_type }}</h1>
          <p class="meta">{{ $t('measure.fields.audit_number') }}: {{ props.measure.audit_number }}</p>
          <div class='meta-grid'>
            <p class="meta">{{ $t('measure.fields.company') }}: {{ props.company.name }}</p>
            <p class="meta">{{ $t('measure.fields.process_code') }}: {{ props.measure.process_code }}</p>
            <p class="meta">{{ $t('measure.fields.measure_follower_email') }}: {{ props.measure.measure_follower_email }}</p>
            <!-- wenn start und end datum gesetzt -->
            <div v-if="props.measure.start_date && props.measure.end_date" class="meta">
              {{ $t('measure.from_to') }}: {{ props.measure.start_date }} - {{ props.measure.end_date }}
            </div>
          </div>
          <div v-if="props.measure.audit_standard" class="audit-standard-badge">{{ props.measure.audit_standard }}</div>
        </div>
        <div :class="`status-badge status-badge-large status-${props.measure.status}`">{{ props.measure.status }}</div>
      </div>
    </div> 

    <div class="card">
      <DxTabPanel>
        <DxItem :title="`${$t('measure.fields.deviation')}`" icon="comment">
          <div v-html="props.measure.deviation" class="html-preview"></div>
        </DxItem>

        <DxItem :title="`${$t('measure.fields.cause')}`" icon="comment">
          <div v-html="props.measure.cause" class="html-preview"></div>
        </DxItem>

        <DxItem
          :title="`${$t('measure.fields.planned_measure')}`"
          icon="comment"
        >
          <div
            v-html="props.measure.planned_measure"
            class="html-preview"
          ></div>
        </DxItem>

        <DxItem :title="`${$t('measure.fields.measure')}`" icon="comment">
          <div v-html="props.measure.measure" class="html-preview"></div>
        </DxItem>

        <DxItem :title="`${$t('measure.fields.risks')}`" icon="comment">
          <div v-html="props.measure.risks" class="html-preview"></div>
        </DxItem>

        <DxItem :title="`${$t('measure.fields.opportunities')}`" icon="comment">
          <div v-html="props.measure.opportunities" class="html-preview"></div>
        </DxItem>

      </DxTabPanel>
    </div>
  </div>
</template>

<script setup>
import { DxTabPanel } from "devextreme-vue/tab-panel";
import { DxButton } from "devextreme-vue/button";
import DxForm, { DxItem } from "devextreme-vue/form";
import DxToolbar, { DxItem as DxToolbarItem } from "devextreme-vue/toolbar";
const props = defineProps({
  measure: {
    type: Object,
    required: true,
  },
  company: {
    type: Object,
    required: true,
  },
  back_path: {
    type: String,
    required: true,
  },
});
</script>

<style>
.header-section{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  h1 {
    font-size: 34px !important;
    font-weight: 300 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 20px;
    color: #777;
    letter-spacing: 1px;
  }
  & > div {
    width: 100%
  }

  .measure-dates{
    font-size: 14px;
    color: #999;
    margin-top: 8px;
    margin-bottom: 20px;
    display: inline;
  }
  .audit-standard-badge{
    background-color: #f0f0f0;
    color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    display: inline-block;
  }

  .meta-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 3px 30px;
  }

  .status-badge{
    /* top right absolute */
    position: absolute;
    width: auto;
    top: 0;
    right: 0;
  }
}


.container {
  padding: 20px;
  min-width: 300px;
  max-width: 1400px;
  margin: 20px auto;
}

.card {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  padding: 20px;
  margin-bottom: 20px;
}

.label {
  font-weight: bold;
}

</style>
