<template>
  <div class="guest-layout">
    <div class="auth-card">
      <div class="dx-card content">
        <div class="header">
          <img :src="props.logo_url" class="logo" alt="IMS Audit Logo" />
          <h1 class="card-title">
            {{ $t("actions.forgot_password") }}
          </h1>
        </div>
        <form class="login-form" :action="props.password_url" method="post">
          <input
            name="authenticity_token"
            type="hidden"
            :value="props.form_authenticity_token"
          />
          <dx-form>
            <dx-item
              :data-field="`${props.resource_name}[email]`"
              editor-type="dxTextBox"
              :editor-options="{
                stylingMode: 'filled',
                placeholder: `${$t('user.fields.email')}`,
                mode: 'email',
                value: '',
              }"
            >
              <dx-required-rule
                :message="`${$t('user.validation.email_required')}`"
              />
              <dx-email-rule
                :message="`${$t('user.validation.email_invalid')}`"
              />
              <dx-label :visible="false" />
            </dx-item>

            <dx-button-item>
              <dx-button-options
                width="100%"
                type="default"
                template="forgotPasswordTemplate"
                :use-submit-behavior="true"
              />
            </dx-button-item>
            <template #forgotPasswordTemplate>
              <div>
                <span class="dx-button-text">
                  <dx-load-indicator
                    v-if="loading"
                    width="24px"
                    height="24px"
                    :visible="true"
                  />
                  <span>{{
                    $t("actions.send_me_reset_password_instructions")
                  }}</span>
                </span>
              </div>
            </template>
          </dx-form>
          <div class="login-link">
            <a :href="props.login_url"> {{ $t("actions.back_to_login") }} </a>
          </div>
        </form>
        <div class="legal-info">
          <p>{{ $t("actions.legal") }}</p>
          <p>
            <a href="https://www.ims-audit.de/impressum" target="_blank">{{ $t("actions.imprint") }}</a> | 
            <a href="https://www.ims-audit.de/datenschutz" target="_blank">{{ $t("actions.data_protection") }}</a> | 
            <a href="https://www.ims-audit.de/newpage" target="_blank">{{ $t("actions.about") }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DxForm, {
  DxItem,
  DxEmailRule,
  DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
} from "devextreme-vue/form";

const props = defineProps({
  resource_name: {
    type: String,
    required: true,
  },
  password_url: {
    type: String,
    required: true,
  },
  form_authenticity_token: {
    type: String,
    required: true,
  },
  logo_url: {
    type: String,
    required: true,
  },
  login_url: {
    type: String,
  },
});

import "@/guest.scss";
</script>

<style scoped lang="scss">
.auth-card {
  display: flex;
  height: 100%;

  :deep(.dx-button-text) {
    text-transform: none;
  }

  .dx-card {
    margin: auto auto;
    padding: 24px;
    flex-grow: 0;
    max-width: 360px;
    border-radius: 8px;
    min-width: 360px;

    &.content {
      height: auto;
    }

    .screen-x-small & {
      width: 100%;
      height: 100%;
      max-width: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 24px;
      margin-top: 24px;

      .logo {
        width: 100%;
        max-width: 200px;
        margin: auto;
        display: block;
      }

      .card-title {
        color: var(--base-text-color);
        text-align: center;
        line-height: 24px;
        font-weight: 500;
        font-size: 24px;
      }

      p {
        text-align: center;
        font-size: 14px;
        color: var(--base-text-color-alpha);
        margin-top: 8px;
      }
    }

    .login-form {
      :deep(.form-text) {
        color: var(--base-text-color-alpha);
      }
    }

    .login-link {
      text-align: center;
      font-size: 12px;
      font-style: normal;
      margin: 6px 0 50px 0;

      a {
        cursor: pointer;
      }
    }

    .legal-info {
      text-align: center;
      font-size: 12px;
      color: #777;
      margin-top: 20px;

      a {
        color: #555;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
