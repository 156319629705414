<template>
  <div class="page-card">
    <DxDataGrid
      :show-borders="true"
      :data-source="props.measures"
      :column-hiding-enabled="true"
      :focused-row-enabled="true"
      :focused-row-key="focusedRowKey"
      key-expr="id"
      @row-click="rowClick"
      :column-auto-width="true"
    >
      <!-- Paging Settings -->
      <DxPaging :enabled="true" :pageSize="5" />

      <!-- Pager Settings -->
      <DxPager
        :visible="true"
        :showPageSizeSelector="true"
        :allowedPageSizes="[5, 10, 20]"
        :showInfo="true"
        :showNavigationButtons="true"
      />
      <DxColumn
        dataField="id"
        :width="50"
        :caption="`${$t('measure.fields.id')}`"
      />
      <DxColumn
        dataField="measure_number"
        :caption="`${$t('measure.fields.measure_number')}`"
      />
      <DxColumn
        dataField="audit_number"
        :caption="`${$t('measure.fields.audit_number')}`"
      />
      <DxColumn
        dataField="start_date"
        :caption="`${$t('measure.fields.start_date')}`"
      />
      <DxColumn
        dataField="end_date"
        :caption="`${$t('measure.fields.end_date')}`"
      />
      <DxColumn
        dataField="measure_type"
        :caption="`${$t('measure.fields.measure_type')}`"
      />
      <DxColumn
        dataField="measure_follower_email"
        :caption="`${$t('measure.fields.measure_follower_email')}`"
      />
      <DxColumn
        dataField="status"
        cell-template="statusTemplate"
        :caption="`${$t('measure.fields.status')}`"
      />

      <DxColumn
        cell-template="actionTemplate"
        :caption="`${$t('measure.fields.actions')}`"
      />

      <DxGridToolbar>
        <DxGridToolbarItem location="before">
          <a :href="props.back_path">
            <DxButton icon="back" type="primary" styling-mode="outlined" />
          </a>
        </DxGridToolbarItem>
        <DxGridToolbarItem location="before">
          <div class="grid-header">
            {{ $t("company.one") }} <b>{{ props.company.name }}</b> /
            {{ $t("user.one") }} <b>{{ props.user.email }}</b> /
            {{ $t("measure.index") }}
          </div>
        </DxGridToolbarItem>

        <DxGridToolbarItem location="after" locate-in-menu="auto">
          <div>
            <DxButton
              :text="`${$t('measure.assign_measure')}`"
              icon="plus"
              type="primary"
              styling-mode="outlined"
              @click="handleAssignMeasure"
            />
          </div>
        </DxGridToolbarItem>
      </DxGridToolbar>

      <template #statusTemplate="{ data }">
        <div :class="`status-badge status-${data.data.status}`">
          {{ data.data.status }}
        </div>
      </template>

      <template #actionTemplate="{ data }">
        <div class="actions">
          <a
            :href="`/admin/companies/${props.company.id}/users/${props.user.id}/measures/${data.data.id}`"
          >
            <DxButton
              :text="`${$t('measure.details')}`"
              icon="datausage"
              type="primary"
              stylingMode="contained"
            />
          </a>
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import DxDataGrid, {
  DxToolbar as DxGridToolbar,
  DxItem as DxGridToolbarItem,
  DxColumn,
  DxPaging,
  DxPager,
} from "devextreme-vue/data-grid";
import { DxButton } from "devextreme-vue/button";
import { DxProgressBar } from "devextreme-vue/progress-bar";
const focusedRowKey = ref(null);

const props = defineProps({
  company: {
    type: Object,
    required: true,
  },
  user: {
    type: Object,
    required: true,
  },
  measures: {
    type: Array,
    required: true,
  },
  admin_company_users_path: {
    type: String,
    required: true,
  },
  back_path: {
    type: String,
    required: true,
  },
});

const rowClick = (event) => {
  focusedRowKey.value = event.data.id;
};

const handleAssignMeasure = () => {
  window.location.href = `/admin/companies/${props.company.id}/users/${props.user.id}/measures/new`;
};
</script>

<style lang="scss">
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
</style>
